<template>
  <section class="ds s-py-45 s-py-xl-75 c-gutter-60">
    <!-- <section class="ds s-py-90 s-py-xl-150 c-gutter-60"> -->
    <div class="container">
      <div class="row">
        <main class="col-lg-12">
          <article>
            <!-- <header class="entry-header">
              <h1 class="entry-title">{{ getCurrentCategory.subtitle }}</h1>
            </header>-->
            <!-- .entry-header -->
            <div class="entry-content">
              <div class="woocommerce">
                <div class="woocommerce-MyAccount-content">
                  <form id="child-form" role="form" style="display: block">
                    <!-- <h3
                      class="mm-font __mm"
                      align="center"
                    >{{ getCurrentCategory.title }}</h3> -->
                    <p class="mm-font __mm" align="center">
                      အောက်ပါပေးထားသော Form အချက်အလက်များကို ဖြည့်ပေးပါ
                    </p>

                    <!-- <label class="mm-font __mm">အမျိုးသမီး</label> -->
                    <br />
                    <br />
                    <label class="form-group">
                      <span class="mm-font __mm __mm">အမည်</span>
                      <input
                        type="text"
                        class="form-control"
                        required
                        v-model="tarot.name"
                        name="name"
                      />
                    </label>
                    <label class="form-group">
                      <span class="mm-font __mm">မွေးသက္ကရာဇ်</span>
                      <!-- <input
                        type="date"
                        class="form-control"
                        name="birth_date"
                        v-model="oneyear.birth_date"
                        required
                      /> -->

                      <md-datepicker
                        v-model="tarot.birthday"
                        name="birthday"
                        value-format="YYYY-MM-DD"
                        class="input-container"
                        required
                      >
                      </md-datepicker>
                    </label>
                    <label class="form-group">
                      <span for class="mm-font __mm">နေ့နံ</span>
                      <select
                        class="mm-font __mm"
                        v-model="tarot.birth_day"
                        name="birth_day"
                        required
                      >
                        <option value="" class="__mm" selected>
                          နေ့နံရွေးချယ်ပါ
                        </option>
                        <option value="တနင်္ဂနွေ" class="__mm">
                          တနင်္ဂနွေ
                        </option>
                        <option value="တနင်္လာ" class="__mm">တနင်္လာ</option>
                        <option value="အင်္ဂါ" class="__mm">အင်္ဂါ</option>
                        <option value="ဗုဒ္ဓဟူး" class="__mm">ဗုဒ္ဓဟူး</option>
                        <option value="ရာဟု" class="__mm">ရာဟု</option>

                        <option value="ကြာသပတေး" class="__mm">ကြာသပတေး</option>
                        <option value="သောကြာ" class="__mm">သောကြာ</option>
                        <option value="စနေ" class="__mm">စနေ</option>
                      </select>
                    </label>
                    <input
                      type="radio"
                      value="Yes"
                      required
                      name="martial_status"
                      v-model="tarot.martial_status"
                    />
                    <span for class="mm-font __mm" style="margin-right: 20px"
                      >အိမ်ထောင်ရှိ</span
                    >
                    <input
                      type="radio"
                      value="No"
                      required
                      name="martial_status"
                      v-model="tarot.martial_status"
                    />
                    <span for class="mm-font __mm">အိမ်ထောင်မရှိ</span>
                    <br /><br />
                    <label class="form-group">
                      <span for class="mm-font __mm">အလုပ်အကိုင်</span>
                      <!-- <input
                        type="text"
                        class="form-control"
                        required
                        v-model="tarot.job"
                        name="job"
                      /> -->
                      <div
                        style="
                          display: grid;
                          grid-template-rows: 1fr 1fr;
                          gap: 5px;
                        "
                      >
                        <select
                          name="job"
                          class="mm-font __mm"
                          v-model="tarot.job"
                          required
                        >
                          <option value="" selected hidden>
                            အလုပ်အကိုင် အမျိုးအစား
                          </option>
                          <option value="အစိုးရဝန်ထမ်း">အစိုးရဝန်ထမ်း</option>
                          <option value="ကုမ္ပဏီဝန်ထမ်း">ကုမ္ပဏီဝန်ထမ်း</option>
                          <option value="ကိုယ်ပိုင်စီးပွားရေး">
                            ကိုယ်ပိုင်စီးပွားရေး
                          </option>
                          <option value="ကျောင်းသား/သူ">ကျောင်းသား/သူ</option>
                          <option value="အခြား">အခြား</option>
                        </select>
                        <input
                          type="text"
                          class="form-control"
                          name="job_detail"
                          v-model="tarot.job_detail"
                          placeholder="အလုပ်ကိုင်အသေးစိတ် "
                        />
                      </div>
                    </label>
                    <input
                      type="radio"
                      value="Male"
                      v-model="tarot.gender"
                      name="gender"
                      required
                    />
                    <span for class="mm-font __mm" style="margin-right: 20px"
                      >ကျား</span
                    >
                    <input
                      type="radio"
                      value="Female"
                      v-model="tarot.gender"
                      name="gender"
                      required
                    />
                    <span for class="mm-font __mm">မ</span>
                    <br /><br />
                    <label class="form-group">
                      <span for="" class="mm-font">
                        Viber အသုံးပြုသော ဖုန်းနံပါတ်အားထည့်ပါ</span
                      >
                      <input
                        type="text"
                        class="form-control"
                        v-model="tarot.viber_no"
                        name="viber_no"
                        placeholder="09xxxxxxxxx"
                        @input="onlyDigit($event)"
                        required
                      />
                    </label>
                    <label class="form-group">
                      <span for class="mm-font __mm">နေရပ်လိပ်စာ</span>
                      <!-- <input
                        type="text"
                        class="form-control"
                        v-model="tarot.address"
                        name="address"
                        required
                      /> -->
                      <div
                        style="
                          display: grid;
                          grid-template-columns: 1fr 1fr;
                          gap: 10px;
                        "
                      >
                        <select
                          v-model="tarot.address_division"
                          required
                          class="mm-font __mm"
                          @change="handleDivisionChange"
                        >
                          <option value="" selected hidden>
                            ပြည်နယ်/တိုင်း
                          </option>
                          <option
                            v-for="d in divisions"
                            :data-nrc="d.nrc_id"
                            :value="d.name"
                            :key="d.name"
                            class="__mm"
                          >
                            {{ d.name }}
                          </option>
                        </select>
                        <select
                          v-model="tarot.address_township"
                          required
                          class="mm-font __mm"
                        >
                          <option value="" selected hidden>မြို့နယ်</option>
                          <option
                            value=""
                            disabled
                            v-if="townships.length == 0"
                          >
                            တိုင်း/ ပြည်နယ် ကိုအရင်ရွေးချယ်ပါ
                          </option>
                          <option
                            v-for="t in townships"
                            :value="t.name"
                            :key="t.name"
                            class="__mm"
                          >
                            {{ t.name }}
                          </option>
                        </select>
                      </div>
                      <input
                        type="text"
                        class="mm-font __mm form-control"
                        placeholder="ရပ်ကွက်/လမ်း အသေးစိတ်"
                        v-model="tarot.address"
                        required
                      />
                    </label>
                    <label class="form-group">
                      <span for class="mm-font__mm"
                        >မေးမြန်းလိုသည့်မေးခွန်းအရေအတွက်</span
                      >
                      <div>
                        <input
                          type="radio"
                          value="2"
                          v-model="tarot.q_quantity"
                          name="q_quantity"
                        />
                        <span style="margin-right: 20px">၂ခု</span>
                        <input
                          type="radio"
                          value="4"
                          v-model="tarot.q_quantity"
                          name="q_quantity"
                        />
                        <span>၄ခု</span>
                      </div>
                    </label>
                    <p class="mm-font">မိမိသိလိုသော ကြောင်းအရာ</p>
                    <label class="form-group">
                      <textarea
                        v-model="tarot.about"
                        name="about"
                        class="mm-font test"
                        rows="10"
                        cols="35"
                        style="color: black"
                        required
                      ></textarea>
                    </label>
                    <p>ဉာဏ်ပူဇော်ခ {{ this.price }} ကျပ် ကျသင့်ပါမည်။</p>
                    <p>
                      <input
                        @click.prevent="handleSubmit()"
                        type="submit"
                        :disabled="disabled"
                        class="woocommerce-Button button mm-font __mm"
                        value="အတည်ပြုမည်"
                      />
                    </p>
                  </form>
                </div>
              </div>
            </div>
            <!-- .entry-content -->
          </article>
        </main>
      </div>
    </div>
  </section>
</template>

<script>
import { getFormData, checkFormModel } from "@core/lib/form-data";
import { Horo, HTTP } from "../../core/lib/http-common";
import { mapGetters } from "vuex";
import { makePayment, onePay, citizenPay } from "../js/payment";
import { getDivisions, getTownships } from "../js/addressInfo";

export default {
  name: "Tarot",
  data() {
    return {
      divisions: [],
      townships: [],
      actionBtnMethod: "",
      disabled: false,
      onepayPhoneNumber: "",
      tarot: {
        name: "",
        birthday: "",
        birth_day: "",
        martial_status: "",
        job: "",
        job_detail: "",
        gender: "",
        viber_no: "",
        address: "",
        address_division: "",
        address_township: "",
        about: "",
        q_quantity: "",
        price: "",
        user_id: "0",
        order_id: "0",
      },
    };
  },
  async created() {
    this.divisions = await getDivisions();
    this.actionBtnMethod = this.$store.state.paymentName;
  },
  mounted() {
    this.onepayPhoneNumber =
      this.$store.state.paymentName == "onepay"
        ? this.$store.state.onepayPhoneNumber
        : "";
  },
  computed: {
    ...mapGetters({
      getUserToken: "userStore/getUserToken",
      getResponse: "userStore/getResponse",
    }),

    price: {
      get() {
        return this.tarot.q_quantity == "2"
          ? 3000
          : this.tarot.q_quantity == "4"
          ? 5000
          : 3000;
      },
    },
  },
  methods: {
    async handleDivisionChange(e) {
      let nrc_id = e.target.options[e.target.selectedIndex].dataset.nrc;
      this.townships = await getTownships(nrc_id);
    },

    onlyDigit($event) {
      let key = $event.keyCode || $event.charCode;
      if ($event.target.value.length > 11 && key != 8) {
        $event.target.value = $event.target.value.substr(
          0,
          $event.target.value.length - 1
        );
      }

      $event.target.value = $event.target.value.replace(/[^\d]/gi, "");
    },

    handleSubmit() {
      this.paymentProvider();
    },
    handleQuantityChange() {},
    paymentProvider() {
      let getPaymentName = this.actionBtnMethod;
      switch (getPaymentName) {
        case "mpitesan":
          this.makePayment();
          break;
        case "onepay":
          this.onePayProvider();
          break;
        case "citizenpay":
          this.citizenPayProvider();
          break;
        case "bppay":
          this.bpPay();
          break;
        default:
          this.precreate();
          break;
      }
    },

    precreate() {
      this.tarot.price = this.price; // this must be set at very first
      if (checkFormModel(this.tarot)) {
        this.disabled = true; // disable button after form validation success
        HTTP.defaults.headers.Authorization = `Bearer ${this.getUserToken}`;
        HTTP.post("subscription/precreate", {
          category_id: 3476,
          amount: this.tarot.price,
          quantity: this.tarot.q_quantity,
          category_name: "tarot",
          name: this.tarot.name,
        })
          .then((response) => {
            const res = response.data;
            this.tarot.user_id = res.user_id;
            this.tarot.order_id = res.order_id;

            let form_data = getFormData(this.tarot);
            form_data.set("price", res.amount);
            form_data.append(
              "user_phone",
              this.getResponse.customer_info.msisdn
            );
            form_data.append("payment_method", "KBZPay");
            form_data.append("srcFrom", "KBZPay");
            Horo.post("1875/tarot/horoscope", form_data).then((response) => {
              this.res_status = response.data.status;
              this.kbzpay.startPay(
                res.prepay_id,
                res.order_info,
                res.sign_app,
                ({ resultCode, lang }) => {
                  if (resultCode == 1) {
                    if (lang == "en") {
                      this.$swal("Success", "Your Purchase Success", "success");
                      this.$router.push({ name: "home" });
                    }
                    if (lang == "my") {
                      this.$swal("အောင်မြင်ပါသည်", "ဝယ်ယူပြီးပါပြီ", "success");
                      this.$router.push({ name: "home" });
                    }
                  }
                  if (resultCode == 2) {
                    if (lang == "en") {
                      this.$swal("Failed", "Your Purchase Failed", "error");
                      this.disabled = false;
                    }
                    if (lang == "my") {
                      this.$swal("မအောင်မြင်ပါ", "ထပ်မံကြိုးစားပေးပါ", "error");
                      this.disabled = false;
                    }
                  }
                },
                () => {
                  this.$swal("Failed", "Your Purchase Failed", "error");
                  this.disabled = false;
                }
              );
            });
          })
          .catch((error) => {
            console.log(error);
            this.message = "Something went wrong";
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    async bpPay() {
      this.tarot.price = this.price;
      if (checkFormModel(this.tarot)) {
        this.disabled = true;

        let form_data = getFormData(this.tarot);
        form_data.append("user_phone", this.tarot.viber_no);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        form_data.append("payment_method", payment_method);
        form_data.append("srcFrom", "PWA BPPay");

        let cdata = {
          category_id: 3476,
          amount: this.tarot.price,
          category_name: "tarot",
          quantity: this.tarot.q_quantity,
          phone_number: this.tarot.viber_no,
          payment_name: this.actionBtnMethod,
        };

        await makePayment([
          "1875/tarot/horoscope",
          form_data,
          cdata,
          this.$store.state.backendUrl,
          this.tarot.price,
        ])
          .then((data) => {
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
              if (!data.payment_status) {
                this.$router.push({
                  name: "paymentPortalV3",
                });
              } else {
                this.$router.push({
                  name: "PaymentSuccess",
                  params: { payment: "bppay" },
                });
              }
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    async makePayment() {
      this.tarot.price = this.price; // this must be set at very first
      if (checkFormModel(this.tarot)) {
        this.disabled = true;

        let form_data = getFormData(this.tarot);
        form_data.append("user_phone", this.tarot.viber_no);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        form_data.append("payment_method", payment_method);
        form_data.append("srcFrom", "MPitesan");
        let cdata = {
          category_id: 3476,
          quantity: this.tarot.q_quantity,
          amount: this.tarot.price,
          category_name: "tarot",
          // promocode: this.promocode,
          phone_number: this.tarot.viber_no,
          payment_name: this.actionBtnMethod,
        };

        await makePayment([
          "1875/tarot/horoscope",
          form_data,
          cdata,
          this.$store.state.backendUrl,
          this.tarot.price,
        ])
          .then((data) => {
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
              if (!data.payment_status) {
                this.$router.push({
                  name: "paymentPortal",
                });
              } else {
                this.$router.push({
                  name: "PaymentSuccess",
                  params: { payment: "mpitesan" },
                });
              }
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    async onePayProvider() {
      this.tarot.price = this.price; // this must be set at very first
      if (checkFormModel(this.tarot)) {
        this.tarot.user_id = this.onepayPhoneNumber;
        this.disabled = true;

        let form_data = getFormData(this.tarot);
        form_data.append("user_phone", this.tarot.viber_no);
        form_data.append("srcFrom", "OnePay");
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        form_data.append("payment_method", payment_method);
        let cdata = {
          category_id: 3476,
          quantity: this.tarot.q_quantity,
          amount: this.tarot.price,
          category_name: "tarot",
          phone_number:
            this.onepayPhoneNumber == ""
              ? this.direct.phone_number
              : this.onepayPhoneNumber,
        };
        await onePay([
          "1875/tarot/horoscope",
          form_data,
          cdata,
          this.$store.state.backendUrl,
          this.tarot.price,
        ])
          .then((data) => {
            if (data.status == 200) {
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    async citizenPayProvider() {
      this.tarot.price = this.price;
      if (checkFormModel(this.tarot)) {
        this.disabled = true;
        let form_data = getFormData(this.tarot);
        form_data.append("user_phone", this.tarot.viber_no);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        form_data.append("payment_method", payment_method);
        form_data.append("srcFrom", "Citizen Pay");

        let cdata = {
          category_id: 3476,
          quantity: this.tarot.q_quantity,
          amount: this.tarot.price,
          category_name: "tarot",
        };
        await citizenPay([
          "1875/tarot/horoscope",
          form_data,
          cdata,
          this.$store.state.backendUrl,
          this.tarot.price,
        ])
          .then((data) => {
            if (data.status == 200) {
              window.location.href = data.paymentGatway;
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },
  },
};
</script>

<style >
input {
  background-color: red;
}

input[type="radio"] {
  display: inline !important;
  margin-right: 5px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.input-container {
  display: flex;
  border: 1px solid #000;
  background-color: #f2eff7;
  color: #3e1e68;
  border-radius: 25px;
  align-items: center;
  padding: 0px 0px 5px 10px;
}

.md-field.md-theme-default:after {
  background-color: rgba(0, 0, 0, 0);
}
.md-button.md-theme-default {
  margin: 0px 16px 0px 0px;
}
</style>