<template>
  <section class="featured-home-post">
    <link
      rel="stylesheet"
      href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css"
      scoped
    />
    <section class="container">
      <div
        class="card p-3"
        style="border-color: #eb6224 !important; border-radius: 20px !important"
      >
        <div class="wrapper">
          <div class="inner">
            <div action="" method="post" class="baydin-form">
              <h4>သင့်မွေးနေ့ကို ရိုက်ထည့်၍ တစ်နှစ်စာဟောစာတမ်း ရယူပါ</h4>
              <br />

              <div class="form-wrapper">
                <label for="" class="label-input">အမည်</label>
                <input
                  type="text"
                  class="form-control"
                  name="name"
                  style="padding-left: 10px !important"
                  v-model="newyearbaydin.name"
                />
              </div>

              <div class="form-wrapper">
                <label for="" class="label-input">ဖုန်းနံပါတ်</label>
                <input
                  type="text"
                  class="form-control"
                  name="name"
                  style="padding-left: 10px !important"
                  v-model="newyearbaydin.phone_number"
                  placeholder="09xxxxxxxxx"
                  @input="onlyDigit($event)"
                />
              </div>

              <div class="form-wrapper form-select">
                <label for="">မွေးသက္ကရာဇ်</label>
                <div class="form-holder">
                  <select
                    name="year"
                    id="year"
                    class="form-control text-center"
                    v-model="newyearbaydin.year"
                  >
                    <option disabled value="" class="option">ခုနှစ်</option>
                    <option v-for="year in years" :value="year" :key="year">
                      {{ year }}
                    </option>
                  </select>
                  <i class="zmdi zmdi-chevron-down"></i>
                </div>

                <div class="form-holder">
                  <select
                    name="month"
                    id="month"
                    style="margin-left: 10px"
                    class="form-control text-center"
                    v-model="newyearbaydin.month"
                  >
                    <option disabled value="" class="option">လ</option>

                    <option
                      v-for="(month, index) in month_list"
                      :key="month"
                      :value="index + 1"
                    >
                      {{ month }}
                    </option>
                  </select>
                  <i class="zmdi zmdi-chevron-down"></i>
                </div>

                <div class="form-holder">
                  <select
                    name="day"
                    id="day"
                    class="form-control text-center"
                    style="margin-left: 10px"
                    v-model="newyearbaydin.day"
                  >
                    <option disabled value="" class="option">ရက်</option>
                    <option v-for="i in 31" :value="i" :key="i">{{ i }}</option>
                  </select>
                  <i class="zmdi zmdi-chevron-down"></i>
                </div>
              </div>

              <div class="form-wrapper">
                <label for="">နေ့နံ</label>
                <div class="form-holder">
                  <select
                    name="day_name"
                    id=""
                    class="form-control"
                    v-model="newyearbaydin.day_name"
                  >
                    <option value="" disabled class="option">နေ့နံ</option>
                    <option value="တနင်္ဂနွေ" class="option">တနင်္ဂနွေ</option>
                    <option value="တနင်္လာ" class="option">တနင်္လာ</option>
                    <option value="အင်္ဂါ" class="option">အင်္ဂါ</option>
                    <option value="ဗုဒ္ဓဟူး" class="option">ဗုဒ္ဓဟူး</option>
                    <option value="ကြာသပတေး" class="option">ကြာသပတေး</option>
                    <option value="သောကြာ" class="option">သောကြာ</option>
                    <option value="စနေ" class="option">စနေ</option>
                    <option value="ရာဟု" class="option">ရာဟု</option>
                  </select>
                  <i class="zmdi zmdi-chevron-down"></i>
                </div>
              </div>

              <button @click.prevent="paymentProvider()" :disabled="disabled">
                ရယူမည်
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end .featured-home-post -->
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { HTTP, ONEYEAR } from "@core/lib/http-common";
import { getFormData, checkFormModel } from "@core/lib/form-data";
import { onePay, makePayment } from "../js/payment";
export default {
  name: `NewYearBaydin`,
  data() {
    return {
      actionBtnMethod: "",
      disabled: false,
      onepayPhoneNumber: "",
      years: [],
      month_list: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],

      newyearbaydin: {
        name: "",
        amount: 0,
        year: "",
        month: "",
        day: "",
        day_name: "",
        phone_number: "",
      },
    };
  },
  created() {
    // this.newyearbaydin.payment_method = this.$store.state.paymentName;
    this.actionBtnMethod = this.$store.state.paymentName;
    for (let i = 1950; i <= new Date().getFullYear(); i++) {
      this.years.push(i);
    }

    this.actionBtnMethod = this.$store.state.paymentName;
    this.onepayPhoneNumber =
      this.$store.state.paymentName == "onepay"
        ? this.$store.state.onepayPhoneNumber
        : "";

    if (this.actionBtnMethod == "onepay") {
      this.newyearbaydin.amount = 3000;
    } else if (this.actionBtnMethod == "mpitesan") {
      this.newyearbaydin.amount = 3000;
    } else if (this.actionBtnMethod == "citizenpay") {
      this.newyearbaydin.amount = 3000;
    } else {
      this.newyearbaydin.amount = 3000;
    }

    ONEYEAR.post("clickCount", {
      payment_method:
        this.$store.state.paymentName == " "
          ? "KBZPay"
          : this.$store.state.paymentName,
    });
  },
  computed: {
    ...mapGetters({
      getUserToken: "userStore/getUserToken",
      getResponse: "userStore/getResponse",
    }),
  },
  methods: {
    onlyDigit($event) {
      let key = $event.keyCode || $event.charCode;
      if ($event.target.value.length > 11 && key != 8) {
        $event.target.value = $event.target.value.substr(
          0,
          $event.target.value.length - 1
        );
      }

      $event.target.value = $event.target.value.replace(/[^\d]/gi, "");
    },

    paymentProvider() {
      let getPaymentName = this.actionBtnMethod;
      switch (getPaymentName) {
        case "mpitesan":
          this.makePayment();
          break;
        case "bppay":
          this.bpPay();
          break;
        case "onepay":
          this.onePayProvider();
          break;
        // case "citizenpay":
        //   this.citizenPayProvicer();
        // break;
        default:
          this.precreate();
          break;
      }
    },

    precreate() {
      if (checkFormModel(this.newyearbaydin)) {
        this.disabled = true;

        HTTP.defaults.headers.Authorization = `Bearer ${this.getUserToken}`;
        HTTP.post("subscription/precreate", {
          category_id: 11001101,
          amount: this.newyearbaydin.amount,
          category_name: "newyearbaydin",
        })
          .then((response) => {
            const res = response.data;
            let formdata = getFormData(this.newyearbaydin);
            formdata.set("amount", res.amount);
            formdata.append("user_id", res.user_id);
            formdata.append("auth1", "1875BaydinUser");
            formdata.append("auth2", "1875BaydinUserP@ssword");
            formdata.append("payment_method", "KBZPAY");
            formdata.append("order_id", res.order_id);
            formdata.append(
              "kpay_number",
              this.getResponse.customer_info.msisdn
            );

            ONEYEAR.post("oneyear", formdata)
              .then((response) => {
                this.res_status = response.data.order_status;
                this.kbzpay.startPay(
                  res.prepay_id,
                  res.order_info,
                  res.sign_app,
                  ({ resultCode, lang }) => {
                    if (resultCode == 1) {
                      if (lang == "en") {
                        this.$swal(
                          "Success",
                          "Your Purchase Success",
                          "success"
                        );
                        this.$router.push({ name: "home" });
                      }
                      if (lang == "my") {
                        this.$swal(
                          "အောင်မြင်ပါသည်",
                          "ဝယ်ယူပြီးပါပြီ",
                          "success"
                        );
                        this.$router.push({ name: "home" });
                      }
                    }
                    if (resultCode == 2) {
                      if (lang == "en") {
                        this.$swal("Failed", "Your Purchase Failed", "error");
                        this.disabled = false;
                      }
                      if (lang == "my") {
                        this.$swal(
                          "မအောင်မြင်ပါ",
                          "ထပ်မံကြိုးစားပေးပါ",
                          "error"
                        );
                        this.disabled = false;
                      }
                    }
                  },
                  () => {
                    this.$swal("Failed", "Your Purchase Failed", "error");
                    this.disabled = false;
                  }
                );
              })
              .catch(() => {
                this.$swal("Failed", "Somethinggg Went Wrong!", "error");
                this.disabled = false;
              });
          })
          .catch((error) => {
            console.log(error);
            this.message = "Something went wrong";
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    async onePayProvider() {
      if (checkFormModel(this.newyearbaydin)) {
        this.disabled = true;
        let formdata = getFormData(this.newyearbaydin);
        formdata.append("user_id", this.onepayPhoneNumber);
        formdata.append("payment_method", "OnePay");
        let cdata = {
          category_id: 11001101,
          amount: this.newyearbaydin.amount,
          category_name: "newyearbaydin",
          phone_number: this.onepayPhoneNumber,
        };

        await onePay([
          "oneyear",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.newyearbaydin.amount,
          "newyearbaydin",
        ])
          .then((data) => {
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
              // this.$router.push({
              //   name: "paymentPortal",
              // });
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    async bpPay() {
      if (checkFormModel(this.newyearbaydin)) {
        this.disabled = true;

        let formdata = getFormData(this.newyearbaydin);
        formdata.append("user_id", this.newyearbaydin.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: 11001101,
          amount: this.newyearbaydin.amount,
          category_name: "newyearbaydin",
        };
        await makePayment([
          "oneyear",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.newyearbaydin.amount,
          "newyearbaydin",
        ])
          .then((data) => {
            // console.log(data)
            if (data.status == 200) {
              // console.log(data);
              this.$swal({
                html: "ငွေ​ပေး​ချေမှု​အောင်မြင်ပြီးပါက ဖြည့်ထား​သော ဖုန်းနံပါတ်သို့ ဗေဒင်အ​ဟောကြည့်ရှုရန် link ကို SMS ​ပေးပို့​ပေးပါမည်။",
                icon: "info",
              }).then(() => {
                this.$store.state.order_id = data.order_id;
                this.$store.state.amount = data.amount;
                this.$router.push({
                  name: "paymentPortalV3",
                });
              });
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            // console.log(error)
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    async makePayment() {
      if (checkFormModel(this.newyearbaydin)) {
        this.disabled = true;
        let formdata = getFormData(this.newyearbaydin);
        formdata.append("user_id", this.newyearbaydin.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: 11001101,
          amount: this.newyearbaydin.amount,
          category_name: "newyearbaydin",
        };
        await makePayment([
          "oneyear",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.newyearbaydin.amount,
          "newyearbaydin",
        ])
          .then((data) => {
            // console.log(data)
            if (data.status == 200) {
              // console.log(data);
              this.$swal({
                html: "ငွေ​ပေး​ချေမှု​အောင်မြင်ပြီးပါက ဖြည့်ထား​သော ဖုန်းနံပါတ်သို့ ဗေဒင်အ​ဟောကြည့်ရှုရန် link ကို SMS ​ပေးပို့​ပေးပါမည်။",
                icon: "info",
              }).then(() => {
                this.$store.state.order_id = data.order_id;
                this.$store.state.amount = data.amount;
                this.$router.push({
                  name: "paymentPortal",
                });
              });
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            // console.log(error)
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },
  },
};
</script>

<style scoped>
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-size: 15px;
  margin: 0;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul {
  margin: 0;
}
h4 {
  color: #eb6224;
  font-weight: 300;
}

img {
  max-width: 100%;
}

ul {
  padding-left: 0;
  margin-bottom: 0;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

textarea {
  resize: none;
}

/* :focus {
            outline: none;
        } */

.wrapper {
  align-items: center;
  float: left;

  margin-top: 20px;
}

.inner {
  display: flex;
  /* margin-left: 270px; */
}

.slider {
  margin-left: -20px;
}

.option {
  color: #eb6224;
  text-align: center;
  width: 30px;
  font-weight: 300;
}

/* .baydin-form {
  background: white;
  padding-top: 63px;
  padding-left: 49px;
  padding-right: 50px;
  margin-top: -10px;
  width: 600px;
  margin-right: 10px;
  border: 2px solid #eb6224;
  border-radius: 25px;
  padding-bottom: 50px;
} */
.container {
  margin-top: 50px;
}
h3 {
  text-align: center;
  margin-bottom: 38px;
  color: white;
}

.form-wrapper {
  color: #fff;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.form-wrapper label {
  width: 32.92%;
}
.form-wrapper label.label-input {
  transform: translateY(5px);
}
label {
  color: #eb6224;
  font-weight: 200px;
}
.form-wrapper label.label-comment {
  transform: translateY(-18px);
}
.form-wrapper.form-select {
  margin-bottom: 10px;
}
.form-wrapper.form-price {
  margin-bottom: 19px;
}

.form-holder {
  position: relative;
}
.form-holder i {
  position: absolute;
  transform: translateY(-21px);
  left: 22px;
}

.form-control {
  width: 67.08%;
  height: 25px;
  display: block;
  font-size: 13px;
  border: none !important;
  border-bottom: 1px solid #eb6224 !important;
  background: none;
  color: #eb6224;
  font-weight: 200px;
  padding: 0;
  margin-bottom: 20px;
}

/* input:focus, textarea:focus {
            border-bottom: 1px solid rgba(255, 255, 255, 0.8);
         } */

select.form-control {
  cursor: pointer;
  width: 60px;
  font-size: 15px;
  transform: translateY(-1px);
}

.baydin-btn {
  height: 42px;
  padding: 10px;
  border-radius: 7.5cm;
  display: inline-flex;
  border: none;
  font-size: 13px;
  color: #222;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  cursor: pointer;
  transition: all 0.5s ease;
  margin-bottom: 20px;
  background: #feed91;
  padding-bottom: 5px;
  margin-right: 15px;
}

input {
  background: #fff;
}

button {
  height: 42px;
  width: 115px;
  border-radius: 7.5cm;
  display: inline-flex;
  border: none;
  font-weight: 200;
  font-size: 13px;
  color: white;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
  transition: all 0.5s ease;
  margin-bottom: 20px;
  background: #eb6224;
  /* padding-bottom: 5px; */
  margin-right: 15px;
}
.btn {
  height: 42px;
  width: 100px;
  border-radius: 7.5cm;
  display: inline-flex;
  border: none;
  font-weight: 200;
  font-size: 13px;
  color: white;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  cursor: pointer;
  transition: all 0.5s ease;
  margin-bottom: 20px;
  background: #eb6224;
  padding-bottom: 5px;
  margin-right: 15px;
}
button:hover {
  background-size: 200% auto;
  background-position: right center;
}

@media (max-width: 590px) {
  /* .baydin-form {
    height: auto;
    margin-left: auto;
    margin-top: 40px;
    padding-left: 30px;
  } */
  h4 {
    font-size: 19px;
  }
  .wrapper {
    float: none;
  }
  .slider {
    margin-left: -0.5em;
    margin-right: -0.9em;
  }
  .inner {
    min-width: auto;
    margin-left: 0;
  }

  form {
    padding-top: 30px;
    padding-bottom: 33px;
  }
  select.form-control {
    font-size: 13px;
  }
  h2 {
    font-size: 14px;
  }
  .slider {
    margin-right: -1.2em;
  }
}

@media (max-width: 890px) {
  /* .baydin-form {
    height: auto;
    width: 350px;
    margin-left: auto;
    padding-left: 30px;
    margin-top: 40px;
  } */
  .inner {
    margin-left: auto;
  }
  select.form-control {
    font-size: 13px;
    margin-left: 10px;
  }
  div .row {
    margin-left: 10px;
  }
  .wrapper {
    background: none;
    float: none;
  }
}

@media (max-width: 1190px) {
}

/*# sourceMappingURL=style.css.map */
</style>
