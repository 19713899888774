<template>
  <section class="ds s-py-45 s-py-xl-75 c-gutter-60">
    <div class="container">
      <div class="row">
        <main class="col-lg-12">
          <article>
            <!-- <header class="entry-header">
              <h1 class="entry-title">{{ getCurrentCategory.subtitle }}</h1>
            </header>-->
            <!-- .entry-header -->
            <div class="entry-content">
              <div class="woocommerce">
                <div class="woocommerce-MyAccount-content">
                  <form id="child-form" role="form" style="display: block">
                    <p class="mm-font">
                      အောက်ပါပေးထားသော Form အချက်အလက်များကို ဖြည့်ပေးပါ
                    </p>
                    <label class="form-group">
                      <label class="mm-font">အမည်</label>
                      <input
                        type="text"
                        class="form-control"
                        name="name"
                        v-model="clairvoyance.name"
                        required
                      />
                    </label>
                    <label class="form-group">
                      <label class="mm-font">မွေးသက္ကရာဇ်(ရက်/လ/နှစ်)</label>
                      <md-datepicker
                        value-format="YYYY-MM-DD"
                        class="input-container"
                        v-model="clairvoyance.birth_date"
                      >
                        <!-- <label>Select date</label> -->
                      </md-datepicker>
                    </label>
                    <label class="form-group">
                      <label class="mm-font">နေ့နံ</label>
                      <select
                        class="mm-font __mm"
                        name="nyih_nan"
                        v-model="clairvoyance.nyih_nan"
                        required
                      >
                        <option value="တနင်္ဂနွေ" class="__mm">
                          တနင်္ဂနွေ
                        </option>
                        <option value="တနင်္လာ" class="__mm">တနင်္လာ</option>
                        <option value="အင်္ဂါ" class="__mm">အင်္ဂါ</option>
                        <option value="ဗုဒ္ဓဟူး" class="__mm">ဗုဒ္ဓဟူး</option>
                        <option value="ရာဟု" class="__mm">ရာဟု</option>

                        <option value="ကြာသပတေး" class="__mm">ကြာသပတေး</option>
                        <option value="သောကြာ" class="__mm">သောကြာ</option>
                        <option value="စနေ" class="__mm">စနေ</option>
                      </select>
                    </label>
                    <input
                      type="radio"
                      name="marital_status"
                      value="Yes"
                      v-model="clairvoyance.marital_status"
                      required
                    />
                    <span for="" class="mm-font"> အိမ်ထောင်ရှိ </span>
                    <input
                      type="radio"
                      name="marital_status"
                      value="No"
                      v-model="clairvoyance.marital_status"
                      required
                    />
                    <span for="" class="mm-font"> အိမ်ထောင်မရှိ </span>
                    <br />
                    <br />
                    <br />
                    <label class="form-group">
                      <label for="" class="mm-font">အလုပ်အကိုင်</label>
                      <div
                        style="
                          display: grid;
                          grid-template-rows: 1fr 1fr;
                          gap: 5px;
                        "
                      >
                        <select
                          name="job"
                          class="mm-font __mm"
                          v-model="clairvoyance.job"
                          required
                        >
                          <option value="" selected hidden>
                            အလုပ်အကိုင် အမျိုးအစား
                          </option>
                          <option value="အစိုးရဝန်ထမ်း">အစိုးရဝန်ထမ်း</option>
                          <option value="ကုမ္ပဏီဝန်ထမ်း">ကုမ္ပဏီဝန်ထမ်း</option>
                          <option value="ကိုယ်ပိုင်စီးပွားရေး">
                            ကိုယ်ပိုင်စီးပွားရေး
                          </option>
                          <option value="ကျောင်းသား/သူ">ကျောင်းသား/သူ</option>
                          <option value="အခြား">အခြား</option>
                        </select>
                        <input
                          type="text"
                          class="form-control"
                          name="job_detail"
                          v-model="clairvoyance.job_detail"
                          placeholder="အလုပ်ကိုင်အသေးစိတ်"
                        />
                      </div>
                    </label>
                    <input
                      type="radio"
                      name="gender"
                      value="Male"
                      v-model="clairvoyance.gender"
                      required
                    />
                    <span for="" class="mm-font">ကျား</span>
                    <input
                      type="radio"
                      name="gender"
                      value="Female"
                      required
                      v-model="clairvoyance.gender"
                    />
                    <span for="" class="mm-font"> မ </span>
                    <br />
                    <br />
                    <div class="form-group">
                      <span class="mm-font" __mm>ဗေဒင်ဆရာ</span>
                      <select
                        class="mm-font __mm"
                        v-model="clairvoyance.baydin_sayar"
                        required
                      >
                        <option value="" selected hidden>
                          ဗေဒင်ဆရာရွေးချယ်ပါ...
                        </option>
                        <option value="ဆရာမစောဝတီနွယ်">ဆရာမစောဝတီနွယ်</option>
                      </select>
                    </div>
                    <br />
                    <p class="mm-font">မေးမြန်းလိုသည့်အကြာင်းအရာကိုရေးပါ။</p>
                    <label class="form-group">
                      <textarea
                        name="about"
                        v-model="clairvoyance.about"
                        class="mm-font test"
                        rows="10"
                        cols="35"
                        placeholder=""
                        style="color: black"
                        required
                      ></textarea>
                    </label>
                    <label class="form-group">
                      <label for="" class="mm-font __mm">
                        Viber အသုံးပြုသော ဖုန်းနံပါတ်အားထည့်ပါ
                        (မှန်ကန်စွာဖြည့်ပေးပါရန်)
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        name="phone_number"
                        v-model="clairvoyance.phone_number"
                        placeholder="09xxxxxxxxx"
                        @input="onlyDigit($event)"
                        required
                      />
                    </label>
                    <br />
                    <div>ဉာဏ်ပူဇော်ခ 10000 ကျပ် ဖြစ်ပါသည်။</div>

                    <p>
                      <input
                        type="submit"
                        @click.prevent="paymentProvider()"
                        :disabled="disabled"
                        class="woocommerce-Button button mm-font __mm"
                        name="save_account_details"
                        value="အတည်ပြုမည်"
                      />
                    </p>
                  </form>
                  <br />
                  <div>
                    <div class="col-md-12">
                      <p class="mm-font __mm cat">
                        ဟောစာတမ်းဖိုင်အား ၂ ရက်အတွင်း
                        အထက်ပါဖြည့်ထားသောဖုန်းနံပါတ်အားပေးပို့ပေးပါမည်။
                        မိမိသိလိုသော မေးခွန်းအားလုံးကိုရေးပေးပြီး မျက်နှာကို
                        ကြည်လင်ပြတ်သားစွာရိုက်ထားသော ဇာတာရှင်၏
                        ဓာတ်ပုံနှင့်အမည်ကို Viber- 09456880335 (သို့မဟုတ်)
                        1875baydin Messenger သို့ပို့ပေးရန်။
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- .entry-content -->
          </article>
        </main>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { Horo, HTTP } from "@core/lib/http-common";
import { getFormData, checkFormModel } from "@core/lib/form-data";
import { makePayment, onePay, citizenPay } from "../js/payment";
import moment from "moment";

export default {
  name: `Clairvoyance`,
  data() {
    return {
      // promocode: "",
      actionBtnMethod: "",
      disabled: false,
      onepayPhoneNumber: "",
      clairvoyance: {
        name: "",
        birth_date: moment(new Date("01/01/1930")).format("YYYY-MM-DD"),
        nyih_nan: "",
        phone_number: "",
        order_id: "0",
        gender: "",
        user_id: "0",
        job: "",
        job_detail: "",
        about: "",
        services: ["files"],
        baydin_sayar: "",
        marital_status: "",
        price: 10000,
      },
    };
  },
  mounted() {
    this.actionBtnMethod = this.$store.state.paymentName;
    this.onepayPhoneNumber =
      this.$store.state.paymentName == "onepay"
        ? this.$store.state.onepayPhoneNumber
        : "";
  },
  computed: {
    ...mapGetters({
      getUserToken: "userStore/getUserToken",
      getResponse: "userStore/getResponse",
    }),
  },
  methods: {
    onlyDigit($event) {
      let key = $event.keyCode || $event.charCode;
      if ($event.target.value.length > 11 && key != 8) {
        $event.target.value = $event.target.value.substr(
          0,
          $event.target.value.length - 1
        );
      }

      $event.target.value = $event.target.value.replace(/[^\d]/gi, "");
    },

    paymentProvider() {
      let getPaymentName = this.actionBtnMethod;
      switch (getPaymentName) {
        case "mpitesan":
          this.makePayment();
          break;
        case "onepay":
          this.onePayProvider();
          break;
        case "citizenpay":
          this.citizenPayProvicer();
          break;
        case "bppay":
          this.bpPay();
          break;
        default:
          this.precreate();
          break;
      }
    },

    async citizenPayProvicer() {
      if (checkFormModel(this.clairvoyance)) {
        this.disabled = true;

        let formdata = getFormData(this.clairvoyance);
        formdata.append("user_phone", this.clairvoyance.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: 3477,
          amount: this.clairvoyance.price,
          category_name: "clairvoyance",
          // phone_number:
          //   this.onepayPhoneNumber == ""
          //     ? this.direct.phone_number
          //     : this.onepayPhoneNumber,
        };
        await citizenPay([
          "1875/clairvoyance/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.clairvoyance.price,
        ])
          .then((data) => {
            if (data.status == 200) {
              window.location.href = data.paymentGatway;
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    async onePayProvider() {
      if (checkFormModel(this.clairvoyance)) {
        this.disabled = true;

        let formdata = getFormData(this.clairvoyance);
        formdata.append("user_phone", this.clairvoyance.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: 3477,
          amount: this.clairvoyance.price,
          category_name: "clairvoyance",
          phone_number:
            this.onepayPhoneNumber == ""
              ? this.clairvoyance.phone_number
              : this.onepayPhoneNumber,
        };
        await onePay([
          "1875/clairvoyance/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.clairvoyance.price,
        ])
          .then((data) => {
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
              // this.$router.push({
              //   name: "paymentPortal",
              // });
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    async bpPay() {
      if (checkFormModel(this.clairvoyance)) {
        this.disabled = true;

        let formdata = getFormData(this.clairvoyance);
        formdata.append("user_phone", this.clairvoyance.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: 3477,
          amount: this.clairvoyance.price,
          category_name: "clairvoyance",
          // promocode: this.promocode,
          phone_number: this.clairvoyance.phone_number,
          payment_name: this.actionBtnMethod,
        };
        await makePayment([
          "1875/clairvoyance/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.clairvoyance.price,
        ])
          .then((data) => {
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
              if (!data.payment_status) {
                this.$router.push({
                  name: "paymentPortalV3",
                });
              } else {
                this.$router.push({
                  name: "PaymentSuccess",
                  params: { payment: "PWA BPPay" },
                });
              }
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    async makePayment() {
      if (checkFormModel(this.clairvoyance)) {
        this.disabled = true;

        let formdata = getFormData(this.clairvoyance);
        formdata.append("user_phone", this.clairvoyance.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        let cdata = {
          category_id: 3477,
          amount: this.clairvoyance.price,
          category_name: "clairvoyance",
          // promocode: this.promocode,
          phone_number: this.clairvoyance.phone_number,
          payment_name: this.actionBtnMethod,
        };
        await makePayment([
          "1875/clairvoyance/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.clairvoyance.price,
        ])
          .then((data) => {
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
              if (!data.payment_status) {
                this.$router.push({
                  name: "paymentPortal",
                });
              } else {
                this.$router.push({
                  name: "PaymentSuccess",
                  params: { payment: "mpitesan" },
                });
              }
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },
    precreate() {
      if (checkFormModel(this.clairvoyance)) {
        this.disabled = true;
        HTTP.defaults.headers.Authorization = `Bearer ${this.getUserToken}`;
        HTTP.post("subscription/precreate", {
          category_id: 3477,
          amount: this.clairvoyance.price,
          category_name: "clairvoyance",
        })
          .then((response) => {
            const res = response.data;
            this.clairvoyance.order_id = res.order_id;
            this.clairvoyance.user_id = res.user_id;
            let formdata = getFormData(this.clairvoyance);
            formdata.append(
              "user_phone",
              this.getResponse.customer_info.msisdn
            );
            formdata.set('price', res.amount);
            Horo.post("1875/clairvoyance/horoscope", formdata).then(
              (response) => {
                this.res_status = response.data.status;
                this.kbzpay.startPay(
                  res.prepay_id,
                  res.order_info,
                  res.sign_app,
                  ({ resultCode, lang }) => {
                    if (resultCode == 1) {
                      if (lang == "en") {
                        this.$swal(
                          "Success",
                          "Your Purchase Success",
                          "success"
                        );
                        this.$router.push({ name: "home" });
                      }
                      if (lang == "my") {
                        this.$swal(
                          "အောင်မြင်ပါသည်",
                          "ဝယ်ယူပြီးပါပြီ",
                          "success"
                        );
                        this.$router.push({ name: "home" });
                      }
                    }
                    if (resultCode == 2) {
                      if (lang == "en") {
                        this.$swal("Failed", "Your Purchase Failed", "error");
                        this.disabled = false;
                      }
                      if (lang == "my") {
                        this.$swal(
                          "မအောင်မြင်ပါ",
                          "ထပ်မံကြိုးစားပေးပါ",
                          "error"
                        );
                        this.disabled = false;
                      }
                    }
                  },
                  () => {
                    this.$swal("Failed", "Your Purchase Failed", "error");
                    this.disabled = false;
                  }
                );
              }
            );
          })
          .catch((error) => {
            console.log(error);
            this.message = "Something went wrong";
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },
  },
};
</script>

<style scoped>
input[type="radio"] {
  display: inline;
  margin-top: 8px;
  margin-left: 3px;
}
input[type="checkbox"] {
  display: inline;
  margin-top: 8px;
}

label {
  display: block;
}

.input-container {
  display: flex;
  border: 1px solid #000;
  background-color: #f2eff7;
  color: #3e1e68;
  border-radius: 25px;
  padding: 8px 0px 0px 10px;
}
.md-field.md-theme-default:after {
  background-color: rgba(0, 0, 0, 0);
}
.md-button.md-theme-default {
  margin: 0px 16px 0px 0px;
}
</style>
