import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";
import userStore from "@horo/core/modules/user/store";
import categoryStore from "@horo/core/modules/category/store";
import envConfig from "../config/envConfig";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        paymentName: " ",
        backendUrl: "",
        backendBPpayUrl: envConfig.appBackendBPpayUrl,
        backendMpiteSanUrl: envConfig.appBackendMpiteSanUrl,
        backendOnepayUrl: envConfig.appBackendOnePayUrl,
        backendCitizenPayUrl: envConfig.appBackendCitizenPayUrl,
        paymentPortalUrl: envConfig.paymentPortalURL,
        paymentPortalV3Url: envConfig.paymentPortalV3URL,
        citizenpayCheckPaymentStatusURL: envConfig.citizenpayCheckPaymentStatus,

        merchant_id: envConfig.bpPayMpitesanMerchantId,
        service_name: envConfig.bpPayMpitesanServiceName,
        email: envConfig.bpPayMpitesanEmail,
        password: envConfig.bpPayMpitesanPassword,
        merchant_key: envConfig.bpPayMpitesanKey,
        amount: 0,
        order_id: "",
        onepayPhoneNumber: "",
        ohmUserPhoneNumber: "",
    },
    mutations: {
        getPaymentName(state, paymentName) {
            state.paymentName = paymentName;
        },

        getOhmUserPhoneNumber(state, ohmUserPhoneNumber) {
            state.ohmUserPhoneNumber = ohmUserPhoneNumber;
        },
    },
    actions: {},
    getters: {
        getRoutePath() {
            return router.currentRoute.path;
        },
        getRouteParams() {
            return router.currentRoute.params;
        },
    },
    modules: {
        userStore,
        categoryStore,
    },
});
